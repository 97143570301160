import { Stack } from "@mui/material";
import { AclPermissions, GroupDto } from "api-shared";
import PermissionsMenu from "./PermissionsMenu";
import GroupDetails from "../group/GroupDetails.tsx";

interface GroupItemProps {
    currentPermission?: AclPermissions;
    group?: GroupDto;
    onClick: (permission: AclPermissions | null) => void;
    buttonLabel: string;
    readonly: boolean;
    description?: string;
    disabled?: boolean;
    allowUpdate?: boolean;
    allowRemove?: boolean;
}

export const GroupItem = ({
    currentPermission,
    group,
    onClick,
    buttonLabel,
    description,
    disabled,
    readonly,
    allowUpdate,
    allowRemove,
}: GroupItemProps) => {
    if (group == null) {
        return null;
    }

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" py={0.5}>
            <GroupDetails group={group} description={description} />
            <PermissionsMenu
                currentPermission={currentPermission}
                allowUpdate={allowUpdate}
                allowRemove={allowRemove}
                disabled={disabled}
                buttonLabel={buttonLabel}
                updateValue={(permission) => onClick(permission)}
                readonly={readonly}
            />
        </Stack>
    );
};

export default GroupItem;
